import React from "react";

import Layout from "../components/layout";
import Header from "../components/header";
import Seo from "../components/seo";
import Events from "../components/events";

const EventsList = ({ pageContext: { eventsList } }) => {
  return (
    <Layout>
      <Seo title="Eventos" />
      <Header />
      <main className="events-list">
        <h1>Eventos</h1>

        <Events
          eventList={eventsList}
          limit={eventsList.data.length}
          withImage={true}
          home={false}
        />
      </main>
    </Layout>
  );
};

export default EventsList;

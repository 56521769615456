import React from "react";
import { Link } from "gatsby";
import { formatURL } from "../utils/helpers";

const Events = (
  { eventList, home = true, limit = 1, withImage = false },
  ...props
) => {
  return (
    <>
      <div className={`events-feed${home ? " home" : ""}`}>
        {eventList.data.slice(0, limit).map((event, index) => {
          return (
            <article key={index}>
              <Link to={`/evento/${formatURL(event.title)}`}>
                {withImage && <img src={event.image} alt="" />}
                <h2>{event.title}</h2>
                <div className="event-description">
                  <span>
                    {new Date(event.date).toLocaleDateString("es-ES", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </span>
                  <span>{event.location}</span>
                </div>
                <p>{event.description}</p>
                <p className={`btn-view-more${home ? "-dark" : ""}`}>Ver más</p>
              </Link>
            </article>
          );
        })}
      </div>
    </>
  );
};

export default Events;
